<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
    <div class="d-flex cursor-pointer" @click="toggleFavourites()">
        <div class="fav-icon" >
            <svg v-if="isInFavourites" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red"
                 class="bi bi-heart-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
            </svg>
            <svg v-if="!isInFavourites && type === 'property'" width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                 class="bi bi-heart" fill="currentColor">
                <g>
                    <title>Layer 1</title>
                    <path id="svg_2"
                          d="m8.02857,1.81586c4.01004,-4.12208 14.03604,3.09111 0,12.36625c-14.03604,-9.27423 -4.01004,-16.48833 0,-12.36625"
                          fill="#ffffff"/>
                    <path id="svg_1"
                          d="m8,2.748l-0.717,-0.737c-1.683,-1.73 -4.769,-1.133 -5.883,1.042c-0.523,1.023 -0.641,2.5 0.314,4.385c0.92,1.815 2.834,3.989 6.286,6.357c3.452,-2.368 5.365,-4.542 6.286,-6.357c0.955,-1.886 0.838,-3.362 0.314,-4.385c-1.114,-2.175 -4.2,-2.773 -5.883,-1.043l-0.717,0.738zm0,12.252c-15.333,-10.132 -4.721,-18.04 -0.176,-13.857c0.06,0.055 0.119,0.112 0.176,0.171a3.12,3.12 0 0 1 0.176,-0.17c4.544,-4.186 15.157,3.723 -0.176,13.856"
                          fill-rule="evenodd"/>
                </g>
            </svg>
            <svg v-if="!isInFavourites && type === 'product'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                <path
                    d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
            </svg>
        </div>
        <div class="ms-2" v-if="showLabel">{{ $t('add-in-favourites') }}</div>
    </div>
</template>

